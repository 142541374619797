@import "../../assets/scss/all/custom/variables.scss";

.map {
  height: 400px;
  overflow: hidden;
  z-index: 1;

  .full-screen-buttons {
    bottom: 15px;
    position: absolute;
    width: 100%;
    z-index: 999;

    .btn-cancel {
      background-color: rgba(255, 255, 255, 0.7) !important;
      color: rgba(0,0,0,0.88)!important;
      border-color: #d9d9d9!important;

      &:hover {
        color: $themecolor!important;
        border-color: $themecolor!important;
      }
    }
  }

  .leaflet-draw-edit-edit,
  .leaflet-draw-edit-remove,
  .leaflet-draw-toolbar {
    display: none;
  }
}

.map.edit-controls-visible {
  .leaflet-draw-edit-edit,
  .leaflet-draw-edit-remove,
  .leaflet-draw-toolbar {
    display: block;
  }
}

.map.editing {
  .leaflet-draw-edit-edit,
  .leaflet-draw-edit-remove,
  .leaflet-draw-toolbar {
    display: block;
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 2px;

  .leaflet-popup-content {
    margin: 10px 15px;
  }
}
