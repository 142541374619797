.country-select.hidden-arrow {
  .ant-select-arrow {
    opacity: 0;
  }
}

.full-width {
  width: 100%;
}

.farm-edit-form {
  .textarea {
    resize: none;
    field-sizing: content;
  }

  label {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    & > label {
      font-weight: 600;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-row {
    background: #fafafa;
  }
}
