.app-dropdown .ant-dropdown-menu-item {
  padding: 3px 12px !important;
}
.app-dropdown .ant-dropdown-menu-item .ant-btn {
  width: 100%;
  justify-content: start;
}
.app-dropdown .ant-dropdown-menu-item .ant-btn-sm {
  padding: 0;
}
.app-dropdown .ant-dropdown-menu-item .app-link {
  padding: 0;
}
.app-dropdown .ant-dropdown-menu-item .app-link:hover {
  color: #51ae32 !important;
}

