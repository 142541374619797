//.profile-header {
//  background-color: transparent;
//  position: sticky;
//  top: 74px;
//  z-index: 999;
//}
.tab-content {
  max-height: calc(100vh - 175px);
  overflow-y: auto;
  overflow-x: hidden;
}
