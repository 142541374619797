.details {
  .item-name {
    display: inline-block;
    width: 200px;
  }
}

.ant-list {
  .ant-list-item {
    display: block;
  }
}

