.geofence-edit-form .textarea {
  resize: none;
  field-sizing: content;
}
.geofence-edit-form label {
  margin-bottom: 0;
}
.geofence-edit-form .ant-form-item-label > label {
  font-weight: 600;
}
.geofence-edit-form .ant-form-item {
  margin-bottom: 0;
}

