.select.hidden-arrow {
  .ant-select-arrow {
    opacity: 0;
  }
}

.select {
  .ant-select-selector {
    background: transparent!important;
  }
}

.full-width {
  width: 100%;
}

.site-edit-form, .farm-form {
  .textarea {
    resize: none;
    field-sizing: content;
  }

  label {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    & > label {
      font-weight: 600!important;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-row {
    background: #fafafa;
  }
}
