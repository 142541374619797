.user-dropdown {
  .ant-dropdown-menu {
    padding: 0;

    .ant-dropdown-menu-item {
      padding: 0.65rem 1rem;
    }

    .ant-dropdown-menu-item:first-child {
      padding: 0;
    }
  }
}

.trigger {
  cursor: pointer;
  height: 64px;
  padding: 0 15px;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
}
