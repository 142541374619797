.country-select.hidden-arrow .ant-select-arrow {
  opacity: 0;
}

.full-width {
  width: 100%;
}

.farm-edit-form .textarea {
  resize: none;
  field-sizing: content;
}
.farm-edit-form label {
  margin-bottom: 0;
}
.farm-edit-form .ant-form-item-label > label {
  font-weight: 600;
}
.farm-edit-form .ant-form-item {
  margin-bottom: 0;
}
.farm-edit-form .ant-row {
  background: #fafafa;
}

