.farm-search-form {
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    & > label {
      font-weight: 600;
    }
  }
}
