@import "../../assets/scss/all/custom/variables.scss";

.app-table {
  .editable_cell {
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0 5px;
    transition: all ease 0.3s;
    height: 21px;

    &:hover {
      border: 1px solid $gray-300;
    }
  }

  .ant-form-item .ant-form-item-control-input{
    height: 21px!important;
    min-height: 21px!important;
  }

  .ant-table-wrapper .ant-table-selection-extra {
    top: 2px;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    display: none;
  }

  50% {
    opacity: 0.5;
    display: block;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

.table-header {
  animation: fadeInOut 1s ease-in-out;
}

.ant-pagination-total-text {
  font-family: sans-serif;
  font-weight: 600;
}

.anticon-search {
  color: rgba(0, 0, 0, 0.25);
}

.search-wrapper {
  position: sticky;
  width: 100%;
  top: 0;
  background: white;
  z-index: 2;
}

.selected-option {
  background-color: rgb(229, 237, 223);
}

.option-item {
  &:hover{
    transition: background-color ease 0.3s;
    background-color: #fafafa;
  }
}
