@import "../../assets/scss/all/custom/variables.scss";

.statusIndicator {
  width: 10px;
  height: 10px;
  float: left;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;

  &.connected {
    background-color: $green;
  }

  &.green {
    background-color: $green;
  }

  &.amber {
    background-color: $amber;
  }

  &.errored {
    background-color: $red;
  }

  &.red {
    background-color: $red;
  }

  &.disconnected {
    background-color: $red;
  }

  &.discharged {
    background-color: $darkGrey;
  }
}
