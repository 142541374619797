.geofence-edit-form {
  .textarea {
    resize: none;
    field-sizing: content;
  }

  label {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    & > label {
      // align-items: start!important;
      font-weight: 600;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}
