.select.hidden-arrow .ant-select-arrow {
  opacity: 0;
}

.select .ant-select-selector {
  background: transparent !important;
}

.full-width {
  width: 100%;
}

.site-edit-form .textarea, .farm-form .textarea {
  resize: none;
  field-sizing: content;
}
.site-edit-form label, .farm-form label {
  margin-bottom: 0;
}
.site-edit-form .ant-form-item-label > label, .farm-form .ant-form-item-label > label {
  font-weight: 600 !important;
}
.site-edit-form .ant-form-item, .farm-form .ant-form-item {
  margin-bottom: 0;
}
.site-edit-form .ant-row, .farm-form .ant-row {
  background: #fafafa;
}

