.calendar-widget-col {
  .rbc-btn-group {
    display: none !important;
  }
}

.no-data {
  padding: 40px;
  text-align: center;
  color: rgba(0,0,0,0.5);
}
