 .app-dropdown {
   .ant-dropdown-menu-item {
     padding: 3px 12px!important;

     .ant-btn {
       width: 100%;
       justify-content: start;
     }

     .ant-btn-sm {
       padding: 0;
     }

     .app-link {
       padding: 0;

       &:hover {
         color: #51ae32 !important;
       }
     }
   }
 }


