.full-screen-animal-map-popup {
  background-color: white;
  border-radius: 4px;
  height: auto;
  left: 30px;
  padding: 10px;
  position: absolute;
  top: 15px;
  width: 200px;
  z-index: 999;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.time-slider {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  .inner {
    align-items: center;
    display: flex;
    flex: 1 1 500px;
    padding-right: 20px;
  }

  .label {
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 20px;
    padding: 0;
    text-wrap: nowrap;
  }
}
