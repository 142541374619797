@import "../../assets/scss/all/custom/variables.scss";

.btn-cancel {
  background-color: transparent !important;
  color: rgba(0,0,0,0.88)!important;
  border-color: #d9d9d9!important;

  &:hover {
    color: $themecolor!important;
    border-color: $themecolor!important;
  }
}
